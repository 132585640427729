import { useContext,useRef,useState,useEffect } from "react";
import { BrowserContext } from "./services/BrowserContext";

const ItemName = (props)=>{
    const {itemsManipulator} = useContext(BrowserContext);
    const {handleRenameItemName} = useContext(BrowserContext);

    const [name,setName] = useState(props.name) ;
    const handleName = (e) => setName(e.target.value);

    const renameInput = useRef(null);
    const cancelBtn = useRef(null);

    const rename = function (e) {
        if(props.name !== name){
            itemsManipulator.path = props.path;
            itemsManipulator.name = props.name;
            itemsManipulator.rename(name);
        }
        handleRenameItemName(null);
    }

    const cancel = () => {
        handleRenameItemName(null);
        setName(props.name);
    }

    const submit = (e) => {
        if (e.key === 'Enter') {
            rename();
        }
        if (e.key === 'Escape') {
            handleRenameItemName(null);
        }
    }

    const onBlurRenameFolder = (event)=>{
        if (cancelBtn.current && cancelBtn.current.contains(event.relatedTarget)) {
            return;
        }
        rename();
    }

    useEffect(() => {
        if(props.rename){
            renameInput.current.focus();
            renameInput.current.select();
        }
    },[props.rename]);

    return props.rename ? <><input ref={renameInput} className="form-control" type="text" value={name} onChange={handleName} onKeyUp={submit} onBlur={onBlurRenameFolder}/><button ref={cancelBtn} onClick={cancel} className="bi bi-x text-danger fs-1 btn btn-link"></button></> : <div>{name}</div>
}

export default ItemName