import { useContext } from "react";
import { BrowserContext } from "../../services/BrowserContext";
import styles from "./breadcrumb.module.css";

const Breadcrumb = (props)=>{
    //console.log('breadcrumb render');
    const {handleCurrentPath} = useContext(BrowserContext);
    const cursor = [];
    
    return <div className="d-flex ms-2">
        <span onClick={(e)=> handleCurrentPath([])} className={styles.folderName + " d-flex align-items-center"}><i className={styles.icon+" bi bi-hdd"}/>Root</span>
        {
            props.path.map(folder => {
                cursor.push(folder);
                let path = Array.from(cursor);
                return <span key={path} className="d-flex align-items-center"><span className={styles.divider}>/</span><span className={styles.folderName} onClick={(e)=> handleCurrentPath(path)}>{folder}</span></span>
            })
        }
        </div>
}
export default Breadcrumb