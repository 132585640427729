import { useContext, useId,useState, memo } from "react";
import styles from "./folderTree.module.css";
import { BrowserContext } from "../../services/BrowserContext";

const FolderTree = memo((props)=>{
    const {handleCurrentPath} = useContext(BrowserContext);

    const [expanded,setExpended] = useState("false") ;
    const [show,setShow] = useState("d-none") ;
    const toggleShow = () => {
        if(show === ""){
            setShow("d-none");
            setExpended('false')
        }else{
            setShow("");
            setExpended('true');
        }
    }

    let path = Array.from(props.parrentPath);
    path.push(props.name);
    const childFolder = props.child.find((folder) => folder.type === 'folder');
    let id = useId();
    return <li className={styles.li}>
                <div className={styles.div}>
                    {
                        childFolder ? 
                        <button onClick={toggleShow} className={styles.button} aria-expanded={expanded}><img alt="toggle" src="/assets/ico/toggle.svg"/></button> : 
                        <div className={styles.emptyFolder}></div>
                    }
                    <div className='d-flex align-items-center flex-fill' onClick={(e)=> handleCurrentPath(path)}>
                        <i className="bi bi-folder-fill text-warning"></i>
                        <p className={styles.folderName}>{props.name}</p>
                    </div>
                    
                </div>
                {
                    childFolder ?
                    <ul className={styles.ul +" "+ show} id={id}>
                        {
                            props.child.map(folder => folder.type === 'folder'  ? <FolderTree parrentPath={path} key={path.join('/')+'/'+folder.name} name={folder.name} child={folder.child}/> : null)
                        }
                    </ul> : ''
                }
            </li>
})

export default FolderTree