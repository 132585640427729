import {useRef} from "react";

const UploadButton = (props) => {
    let fileInput = useRef(null);

    function addFile(e) {
        e.preventDefault();
        fileInput.current.click();
    }

    return <form onSubmit={e => addFile(e)}>
                <button id="addFile" type="submit" className="btn btn-secondary"><i className="bi bi-file-earmark-arrow-up"></i> Upload File</button>
                <input className="file-input" type="file" name="file" onChange={e => props.uploader.addUploadFiles(e.target.files,props.path)} ref={fileInput} multiple hidden/>
            </form>
}

export default UploadButton