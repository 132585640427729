import { useContext } from "react";
import { BrowserContext } from "./services/BrowserContext";
import Dropdown from 'react-bootstrap/Dropdown';

const ContentActionMenu = (props)=>{
    const {itemsManipulator} = useContext(BrowserContext);
    const {handleRenameItemName} = useContext(BrowserContext);

    const deleteItem = function (e) {
        if(window.confirm("Are you sure you want to delete "+props.name+"?")){
            itemsManipulator.path = props.path;
            itemsManipulator.name = props.name;
            itemsManipulator.delete();
        }
    }

    const renameItem = function (e) {
        handleRenameItemName(props.name);
    }

    const download = function() {
        itemsManipulator.name = props.name;
        itemsManipulator.path = props.path;
        itemsManipulator.download();
    }

    return (
        <Dropdown align="start">
          <Dropdown.Toggle as="i" className="bi bi-three-dots-vertical" bsPrefix=" " type="button">
          </Dropdown.Toggle>
    
          <Dropdown.Menu>
            <Dropdown.Item as="div" onClick={download}>Download</Dropdown.Item>
            <Dropdown.Item as="div" onClick={deleteItem}>Delete</Dropdown.Item>
            <Dropdown.Item as="div" onClick={renameItem}>Rename</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      );
}
export default ContentActionMenu