//import Modal from "./Modal";
import Modal from 'react-bootstrap/Modal';
import {Carousel as BootstrapCarousel} from 'react-bootstrap';
import { useContext, useState, useEffect } from "react";
import { BrowserContext } from "../../services/BrowserContext";


const Carousel = ({carouselActiveFileName,elements,path})=>{

    const currentPath = encodeURI(path.join('/'));

    const {handleCarouselActiveFileName} = useContext(BrowserContext);
    const handleClose = (e) => {
        handleCarouselActiveFileName(null);
    }
    const carouselItems = elements.child.filter((element) => {
        const extension = element.name.toLowerCase().split('.').pop();
        return ['apng','avif','gif','jpg','jpeg','jfif','pjpeg','pjp','png','svg','webp','bmp','ico','cur','tif','tiff'].includes(extension);
    });

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        const selectedItems = carouselItems.findIndex((element) => element.name === carouselActiveFileName);
        setIndex(selectedItems);
    },[carouselActiveFileName]);


    return (
        <Modal id="carouselModal" show={carouselActiveFileName} size="xl" onHide={handleClose}>
            <BootstrapCarousel interval={null} className='bg-dark' activeIndex={index} onSelect={handleSelect}>
                {
                    carouselItems.map(function (element) {
                        return (
                        <BootstrapCarousel.Item  key={path+'/'+element.name}>
                                <img alt={element.name} src={process.env.REACT_APP_API_URL+"?action=download&path="+currentPath+encodeURI('/')+encodeURI(element.name)} className="d-block w-100 object-fit-contain" style={{height : "90vh"}}/>
                                <BootstrapCarousel.Caption>
                                    <h3 style={{textShadow : "#000000 1px 0 10px"}}>{element.name}</h3>
                                </BootstrapCarousel.Caption>
                        </BootstrapCarousel.Item>
                        )
                        
                    })
                }
            </BootstrapCarousel>
            <div className='z-1 position-absolute top-0 end-0 bg-white'><button type="button" className="btn-close" onClick={handleClose}></button></div>
        </Modal>
      );

}

export default Carousel