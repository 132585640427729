import {useState,useContext, useRef,useEffect} from "react";
import { BrowserContext } from "./services/BrowserContext";
import Folder from "./Folder";
import File from "./File";
import Image from "./files/Image";
import Video from "./files/Video";
import Text from "./files/Text";
import Pdf from "./files/Pdf";


const Content = ({elements,path,newFolder,handleNewFolder,renameItemName,handleRenameItemName})=>{
    const [name,setName] = useState('New Folder') ;
    const handleName = (e) => setName(e.target.value);

    const {itemsManipulator} = useContext(BrowserContext);

    const valid = ()=>{
        itemsManipulator.name = name === "" ? "New Folder" : name;
        itemsManipulator.path = path;
        itemsManipulator.create();
        handleNewFolder(false);
        setName("New Folder");
    }

    const submit = (e) => {
        if (e.key === 'Enter') {
            valid();
        }
    }
    const cancel = () => {
        setName("New Folder");
        handleNewFolder(false);
    }

    const newFolderInput = useRef(null);
    const cancelBtn = useRef(null);

    const onBlurNewFolder = (event)=>{
        if (cancelBtn.current && cancelBtn.current.contains(event.relatedTarget)) {
            return;
        }
        valid();
    }

    useEffect(() => {
        if(newFolder){
            newFolderInput.current.focus();
            newFolderInput.current.select();
        }
    },[newFolder]);
    
    return (
        <>
        {
            newFolder ?
                <div className="list-group-item list-group-item-action justify-content-between d-flex align-items-center">
                <div className="d-flex align-items-center">
                    <i className="bi bi-folder-fill text-warning"></i>
                    <input ref={newFolderInput} type="text" value={name} className="form-control" onChange={handleName} onKeyUp={submit} onBlur={onBlurNewFolder}/>
                    <button ref={cancelBtn} onClick={cancel} className="bi bi-x text-danger fs-1 btn btn-link"></button>
                </div>
            </div> : ''
        }
        {
            elements.child.map(function (element) {
                const extension = element.name.toLowerCase().split('.').pop();
                if (element.type === 'folder') {
                    return <Folder key={path+'/'+element.name} path={path} name={element.name} rename={renameItemName === element.name}/>
                }
                if(['apng','avif','gif','jpg','jpeg','jfif','pjpeg','pjp','png','svg','webp','bmp','ico','cur','tif','tiff'].includes(extension)){
                    return <Image key={path+'/'+element.name} path={path} name={element.name} size={element.size} rename={renameItemName === element.name}></Image>
                }
                if(['mkv','avi','m2ts','mpg','mpeg','mp4','mov','wmv','flv','webm','mts','rm','mp2','mpv','3gp','3g2'].includes(extension)){
                    return <Video key={path+'/'+element.name} path={path} name={element.name} size={element.size} rename={renameItemName === element.name}></Video>
                }
                if(['txt','m3u','xml'].includes(extension)){
                    return <Text key={path+'/'+element.name} path={path} name={element.name} size={element.size} rename={renameItemName === element.name}></Text>
                }
                if(['pdf'].includes(extension)){
                    return <Pdf key={path+'/'+element.name} path={path} name={element.name} size={element.size} rename={renameItemName === element.name}></Pdf>
                }
                return <File key={path+'/'+element.name} path={path} name={element.name} size={element.size} rename={renameItemName === element.name}><File.Ico><i className="bi bi-file-earmark fs-4 me-1 text-dark"></i></File.Ico></File>
            })
        }
        </>
    );
}
export default Content