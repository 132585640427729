import { useEffect, useState, useContext} from "react";
import './App.css';
import FolderTree from './molecules/FolderTree/FolderTree';
import Breadcrumb from "./molecules/Breadcrumb/Breadcrumb";
import Content from "./Content";
import FileProgress from "./FileProgress";
import UploadBtn from "./atoms/UploadButton";
import Uploader from "./services/Uploader";
import NewFolderBtn from "./atoms/NewFolderButton";
import { BrowserContext } from "./services/BrowserContext";
import cssFolderTree from "./molecules/FolderTree/folderTree.module.css";
import Historical from "./Historical";
import Login from "./molecules/modals/Login";
import Profile from "./Profile";
import PlexRefreshButton from "./atoms/PlexRefreshButton";
import Carousel from "./molecules/modals/Carousel";

const elements = {'name' : 'Root', 'type' : 'folder', 'child' :[]};
let currentFolder = elements;
const rootPath = [];
var uploader = new Uploader();

function getPathFromUrl(){
    let path = decodeURI(document.location.pathname).split('/').slice(2);
    if(path.toString() === ''){
        path = [];
    }
    return path;
}

function App() {
    //console.log('app');
    window.onpopstate = function(event) {
        handleCurrentPath(getPathFromUrl(),false);
    }
    const {itemsManipulator} = useContext(BrowserContext);
    const [currentPath,setPath] = useState([]) ;
    const handleCurrentPath = (path,history = true) => {
        if(currentPath.toString() !== path.toString()){
            //console.log(path);
            if(history){
                window.history.pushState([], null, '/root/'+path.join('/'));
            }
        }
        currentFolder = elements;
        path.forEach(folderName => currentFolder = currentFolder.child.find( element => element.name === folderName && element.type === 'folder'));
        currentFolder.child.sort(function(a,b){
            if(a.type === 'folder' && b.type === 'file'){
                return -1;
            }
            if(b.type === 'folder' && a.type === 'file'){
                return 1;
            }
            return a.name.localeCompare(b.name);
        });

        setPath(Array.from(path));
    }

    const [fileInfo,setFileInfo] = useState(false) ;
    const handleFileInfo = (fileInfo) => setFileInfo(fileInfo);

    const [user,setUser] = useState(false) ;
    const handleUser = (user) => setUser(user);

    const [historical,setHistorical] = useState([]) ;
    const handleHistorical = (row) => {
        historical.push(row);
        setHistorical(Array.from(historical));
    }

    const [renameItemName,setRenameItemName] = useState(null);
    const handleRenameItemName = (name) => setRenameItemName(name);

    const [carouselActiveFileName,setCarouselActiveFileName] = useState(null);
    const handleCarouselActiveFileName = (name) => {
        setCarouselActiveFileName(name);
    }

    const [newFolder,setNewFolder] = useState(false) ;
    const handleNewFolder = (state) => setNewFolder(state);

    uploader.handleFileInfo = handleFileInfo;
    uploader.handleHistorical = handleHistorical;
    uploader.itemsManipulator = itemsManipulator;
    itemsManipulator.elements = elements;
    itemsManipulator.handleUser = handleUser;
    itemsManipulator.handleCurrentPath = handleCurrentPath;

    useEffect(() => {
        itemsManipulator.path = getPathFromUrl();
        itemsManipulator.get();
        itemsManipulator.getUser();
    },[itemsManipulator]);
    
  return (
    <BrowserContext.Provider value={{handleCurrentPath,handleCarouselActiveFileName,handleRenameItemName,itemsManipulator}}>
        <div id="browser-container" className="container-fluid">
            <nav id="header" className="navbar navbar-light bg-light">
                <div className="container-fluid">
                    <span className="navbar-brand mb-0 h1">ClodoDrive</span>
                    <Profile user={user} handleUser={handleUser}></Profile>
                </div>
            </nav>
            <div id="folderTree" className="border rounded bg-white">
                <div className={cssFolderTree.div+" ms-2"} onClick={(e)=> handleCurrentPath([])}>
                    <button className={cssFolderTree.button} data-bs-toggle="collapse" data-bs-target={"#root"} aria-expanded="true"><img alt="toggle" src="/assets/ico/toggle.svg"/></button>
                    <i className="bi bi-hdd"></i>
                    <p className={cssFolderTree.folderName}>Root</p>
                </div>
                <ul id="root" className="list-unstyled collapse show">
                    {
                        elements.child.map(folder => folder.type === 'folder'  ? <FolderTree parrentPath={rootPath} key={'/'+folder.name} name={folder.name} child={folder.child}/>  : null)
                    }
                </ul>
            </div>
            <div id="uploadList" className="folder border rounded bg-white">
                <Historical elements={historical}></Historical>
            </div>
            <nav id="breadcrumb" aria-label="breadcrumb" className="border rounded bg-white">
                <Breadcrumb path={currentPath}/>
            </nav>
            <div id="actionArea" className="d-flex flex-wrap gap-3">
                    <UploadBtn uploader={uploader} path={currentPath}/>
                    <NewFolderBtn handleNewFolder={handleNewFolder}/>
                    <PlexRefreshButton server={user.plex_server} port={user.plex_port} library={user.plex_library} token={user.plex_token}/>
            </div>
            
            <div className="bg-white border rounded" id="content" onDrop={e => uploader.dropFiles(e,currentPath)} onDragOver={e => e.preventDefault()}>
                <div className="row border-bottom p-3 fw-bold m-0">
                    <div className="col-md-8 col-lg-9 col-xl-10">Name</div><div id="sizeHeader" className="col-md-4 col-lg-3 col-xl-2">Size</div>
                </div>
                <div id="content-items" className="list-group p-3 list-group-flush">
                    <Content elements={currentFolder} path={currentPath} newFolder={newFolder} handleNewFolder={handleNewFolder} renameItemName={renameItemName} handleRenameItemName={handleRenameItemName}/>
                </div>
            </div>
            <div id="fileProgress">
                <FileProgress fileInfo={fileInfo}/>
            </div>
        </div>
        <Carousel elements={currentFolder} carouselActiveFileName={carouselActiveFileName} path={currentPath}></Carousel>
        <Login></Login>
        
      </BrowserContext.Provider>
  );
}


export default App;
