import { useContext, useState,useEffect } from "react";
import { BrowserContext } from "../../services/BrowserContext";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Login = ()=>{
    const {itemsManipulator} = useContext(BrowserContext);

    const [name,setName] = useState('') ;
    const handleName = (e) => setName(e.target.value);

    const [password,setPassword] = useState('') ;
    const handlePassword = (e) => setPassword(e.target.value);

    const [show,setShow] = useState(false) ;
    const handleShow = (show) => setShow(show);
    const handleClose = () => setShow(false);

    const login = () => {
        itemsManipulator.login(name,password);
        setPassword('');
        setShow(false);
    }

    const submit = (e) => {
        if (e.key === 'Enter') {
            handleShow(false);
            login();
        }
    }
    useEffect(() => {
        itemsManipulator.displayLoginModal = handleShow;
    },[itemsManipulator]);

    return <Modal id="loginModal" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="mb-3">
                <label htmlFor="username" className="form-label">Username</label>
                <input id="username" value={name} onChange={handleName} type="text" className="form-control"></input>
            </div>
            <div className="mb-3">
                <label htmlFor="password" className="form-label">Password</label>
                <input id="password" value={password} onChange={handlePassword} onKeyUp={submit} type="password" className="form-control"></input>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button variant="primary" onClick={login}>
                Login
            </Button>
        </Modal.Footer>
    </Modal>
}
export default Login